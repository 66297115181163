@import '../../Assets/Fonts/fonts.scss';
@import '../../Styles/variables.scss';

.login-container {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $outside-background-color;

  .title {
    font-family: 'MuseoSans';
    font-size: 0.7rem;
    color: $logo-text-color;
    font-weight: 700;
    margin-right: 0.5rem;
    margin-top: -10px;
  }

  .title-sub {
    font-weight: 100 !important;
    font-family: 'MuseoSans';
    font-size: 0.7rem;
    color: $logo-text-color;
    padding-left: 6px;
  }

  .country-name {
    font-size: 0.6rem;
    font-family: 'MuseoSans';
    margin-top: -3px;
  }

  .login-language-selection-container {
    position: absolute;
    bottom: 14px;
    left: 2rem;
    font-weight: regular;
    font-size: 0.8rem;
    color: $body-text-color;

    .login-language-selection-txt {
      font-size: 0.8rem;
      font-weight: 500;
    }

    .ant-select-selector {
      border: none !important;
      color: $body-text-color !important;
      font-size: 0.8rem;
      padding: 0px !important;
    }

    .ant-select-selection-item {
      color: $body-text-color !important;
    }

    .ant-select-arrow {
      color: $body-text-color !important;
    }

    .sep {
      padding: 0px 5px;
    }

    .ant-select {
      width: 100px;
    }
  }
  .container-image {
    background-image: linear-gradient(
        to bottom,
        rgba(196, 196, 196, 0) 0%,
        rgba(62, 127, 106, 0.484) 0.01%
      ),
      url('../../Assets/Images/login_main.webp');
    background-image: linear-gradient(
        to bottom,
        rgba(196, 196, 196, 0) 0% rgba(4, 104, 177, 0.6) 0.01%
      ),
      image-set(
        '../../Assets/Images/login_main.png' type('image/png'),
        '../../Assets/Images/login_main.webp' type('image/webp')
      );
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(hsla(0, 0%, 77%, 0), rgba(4, 104, 177, 0.6))
      ),
      image-set(
        '../../Assets/Images/login_main.png' type('image/png'),
        '../../Assets/Images/login_main.webp' type('image/webp')
      );
    background-size: cover;
  }

  .login-img-container {
    height: 100vh;
    background-position-x: 90%;
    background-position-y: 100%;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    flex: 1;
    text-align: left;
    background-size: 150vh;

    @media (max-width: $lg-size) {
      text-align: center;
      justify-content: center;
    }

    .text-ctn {
      margin-left: 2vw;
      margin-bottom: 8vh;
      font-family: $secondary-font-family;
      font-size: 4rem;
      color: $outside-background-color;
      line-height: 1.2em;
      text-transform: uppercase;

      @media (max-width: $lg-size) {
        font-size: 2.2rem;
        margin-top: 1rem;
        margin-bottom: unset;
        line-height: 1.5em;
      }
    }
  }

  .login-country-logo {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    img {
      margin-top: 20px;
      object-fit: cover;
      height: 45px;
    }
  }

  .login-country-name {
    font-size: 0.6rem;
    margin-left: 15px;
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-top: 1.5vh;
    color: $title-text-color;

    .title {
      font-size: 1rem;
    }

    .title-sub {
      font-size: 1rem;
    }

    .country-name {
      font-size: 0.7rem;
    }

    span {
      @media (max-width: $lg-size) {
        text-align: center;
      }
    }
  }

  .login-text-contents {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;

    @media (max-width: $lg-size) {
      text-align: center;
    }

    .login-text-signin {
      font-size: 2.25rem;
      line-height: 2.5rem;
      color: $title-text-color;
      font-weight: 600;

      .login-text-welcome {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }

  .forgot-submit-btn-container {
    margin-top: 1rem;
  }

  .forgot-input-fields-container {
    margin-top: 4vh;
    height: 28vh;

    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      color: $dark-text-color;
      border-color: none;
      background: $line-colors;
      text-shadow: none;
      box-shadow: none;
    }

    .logged-out-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 1.2rem;
      align-items: center;
      justify-content: space-between;

      .info-icon {
        display: flex;
        width: 5%;
      }
      .msg {
        display: flex;
        width: 95%;
        color: $error-color;
        font-size: 0.8rem;
      }
    }
  }

  .login-input-fields-container {
    margin-top: 3vh;
    height: 15vh;

    .confirm-password {
      .ant-form-item-explain-error {
        margin-top: 0.3rem !important;
      }
    }

    .logged-out-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 1.2rem;
      align-items: center;
      justify-content: space-between;

      .info-icon {
        display: flex;
        width: 5%;
      }
      .msg {
        display: flex;
        width: 95%;
        color: $error-color;
        font-size: 0.8rem;
      }
    }
  }

  .email-success-msg {
    font-size: 14px;
    margin-top: 0.5rem;
    color: $primary-color;
  }

  .login-input-fields {
    @media (max-width: $lg-size) {
      margin: auto;
      padding: 30px;
      width: 100vw;
      margin-bottom: 350px;
    }

    .login-error-message-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .ant-form-item {
      margin-bottom: 14px;

      .login-input-email {
        margin-bottom: 0.2rem;
      }

      .ant-form-item-explain-error {
        margin-bottom: 0.2rem;
      }

      #login-details_password_help {
        margin-top: 0.2rem;
      }
    }

    .login-forget-pwd-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      flex: 1;
      margin-top: 0.2rem;

      .login-forget-pwd-txt {
        font-size: 0.8rem;
        color: $title-text-color;
        cursor: pointer;
      }
    }

    .bottom-forgot-password-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 1.5rem;
      justify-content: center;
      color: $note-light-text;
      font-weight: 400;

      .backto-signin-txt {
        color: $primary-color;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .login-submit-btn-container {
      margin-top: 2rem;
    }

    .login-register-new-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 1rem;
      font-size: 0.9rem;
      color: $body-text-color;

      .login-register-new-txt {
        font-size: 0.8rem;
        color: $body-text-color;
        cursor: pointer;

        .login-register-new-txt-span {
          color: $primary-color;
          font-weight: 600 !important;
        }
      }
    }

    .ant-form-item-label {
      label {
        color: $title-text-color;
      }
    }

    .login-input-email {
      .ant-input {
        height: 2.8rem;
        border-radius: 0.3rem;
        font-size: 0.8rem;
        border: 1px solid $line-colors;
        padding-left: 1.5rem;
        color: $title-text-color;
      }

      input[type='username']:-webkit-autofill,
      input[type='username']:-webkit-autofill:hover,
      input[type='username']:-webkit-autofill:focus {
        -webkit-text-fill-color: $title-text-color;
        -webkit-box-shadow: 0 0 0 30px $outside-background-color inset !important;
      }

      .ant-input:focus {
        box-shadow: none;
      }
    }

    .login-input-password {
      .ant-input {
        height: 2.8rem;
        font-size: 0.8rem;
        border-radius: 0.3rem;
        padding-left: 0.8rem;
        box-shadow: none;
      }

      .ant-input-password {
        border: 1px solid $line-colors;
        border-radius: 0.3rem;
        box-shadow: none;
        background-color: $outside-background-color !important;
        padding: 0px 11px;
      }

      input[type='password']:-webkit-autofill,
      input[type='password']:-webkit-autofill:hover,
      input[type='password']:-webkit-autofill:focus {
        -webkit-text-fill-color: $title-text-color;
        -webkit-box-shadow: 0 0 0 30px $outside-background-color inset !important;
      }
    }
  }

  .ant-input::placeholder {
    font-size: 1rem;
    color: $light-text-color;
  }

  .ant-btn-primary {
    background: linear-gradient(to right, $gradient-background-start, $gradient-background-end);
    border: none;
    height: 2.4rem;
    font-size: 1rem;
    border-radius: 0.3vw;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  }

  .ant-select-selector {
    border: none;
    color: $background-color;
  }
}

.forgot-password-container {
  .note-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 1.5rem;
    font-size: 0.875rem;
    color: $note-light-text;
    text-align: justify;

    .label {
      margin-right: 0.5rem;
    }
  }
}
